var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from './Card/Card';
import { SingleCard } from './SingleCard/SingleCard';
import { BuildingCard } from './BuldingCard/BuldingCard';
import { useWindowWidth } from 'src/useWindowWidth';
import { EDIT_DETAILS, M, ROUTES } from 'src/Utils';
import { getBuildingData, getValuesBookedBy, getValuesAmenity, getShedulesValues, getDateValues, getExtrasValues, getCostValues, } from './utils';
import { Column, Container, Link, MainRow, Row } from './styles';
import { useAmenity } from '../hooks/useAmenity';
export var Information = function (_a) {
    var reservation = _a.reservation;
    var history = useHistory();
    var windowWidth = useWindowWidth();
    var isMobile = windowWidth < M;
    var isAdmin = reservation.created_by.is_admin;
    var spanText = isAdmin ? 'Admin' : reservation.numeric_client_code;
    var buildingData = getBuildingData(reservation);
    var valuesBookedBy = getValuesBookedBy(reservation, isAdmin);
    var valuesAmenity = getValuesAmenity(reservation);
    var shedulesValues = getShedulesValues(reservation);
    var dateValues = getDateValues(reservation);
    var extrasValues = getExtrasValues(reservation);
    var costValues = getCostValues(reservation);
    var _b = useAmenity({
        amenityId: reservation === null || reservation === void 0 ? void 0 : reservation.amenity_id,
        selectedExtras: extrasValues === null || extrasValues === void 0 ? void 0 : extrasValues[0],
        currency: reservation === null || reservation === void 0 ? void 0 : reservation.currency,
        day: reservation.day,
        time: reservation.schedule,
    }), extras = _b.extras, basePrice = _b.basePrice;
    return (React.createElement(Container, null,
        React.createElement(MainRow, null,
            React.createElement(BuildingCard, { values: buildingData }),
            React.createElement(Card, { values: valuesAmenity, title: "Datos de contacto" })),
        isMobile ? (React.createElement(React.Fragment, null,
            React.createElement(Card, { values: valuesBookedBy, title: "Reservado Por", span: spanText }),
            React.createElement(SingleCard, { values: shedulesValues, width: "auto", height: "auto" }),
            React.createElement(SingleCard, { values: dateValues, width: "auto", height: "auto" }),
            React.createElement(SingleCard, { values: extras, width: "fit-content", height: "fit-content" }),
            React.createElement(SingleCard, { values: __spreadArrays(costValues, basePrice), width: "fit-content", height: "fit-content" }))) : (React.createElement(MainRow, null,
            React.createElement(Row, null,
                React.createElement(Card, { values: valuesBookedBy, title: "Reservado Por", span: spanText, classname: "small" }),
                React.createElement(Column, null,
                    React.createElement(SingleCard, { values: dateValues, width: "auto", height: "30%" }),
                    React.createElement(SingleCard, { values: shedulesValues, width: "auto", height: "70%" }))),
            React.createElement(Column, { className: "width" },
                React.createElement(SingleCard, { values: extras, width: "35%", height: "50%" }),
                React.createElement(SingleCard, { values: __spreadArrays(costValues, basePrice), width: "35%", height: "50%" })))),
        reservation.status === 'active' && (React.createElement(Link, { onClick: function () {
                return history.push(ROUTES.edit_reservation.replace(':id', String(reservation.id)));
            } }, EDIT_DETAILS))));
};
