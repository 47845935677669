import React, { useState } from 'react';
import moment from 'moment';
import { AMENITIES_NOT_AVAILABLE, AMENITY, AMENITY_KEY, APT, APT_KEY, BUILDING, BUILDING_KEY, CLIENT_CODE, CLIENT_KEY, RESERVATION_DATE, SCHEDULE, SCHEDULES_NOT_AVAILABLE, TIME_KEY, } from 'src/Utils';
import { InputComponent, Popover, SelectComponent, } from 'src/Components/Common';
import { openedArrow } from 'src/assets/Images';
import CalendarComponent from 'src/Components/Common/Calendar/CalendarComponent';
import { CustomInput } from 'src/Components/ReceiptForm';
import useClientCode from './hooks/useClientCode';
import { API_DATA } from 'src/Utils/mockData';
import { useAmenitiesAndSchedules } from './hooks/useAmenitiesAndSchedule';
import { BoxContainer, InputContainer, Label } from '../styles';
import { useAppSelector } from '../../../redux/hooks';
export var ReservationForm = function (_a) {
    var _b;
    var setExtras = _a.setExtras, amenity = _a.amenity, setAmenity = _a.setAmenity, clientCode = _a.clientCode, setClientCode = _a.setClientCode, date = _a.date, setDate = _a.setDate, time = _a.time, setTime = _a.setTime;
    var _c = useAppSelector(function (state) { return state.tableFiltersSlice; }), selectedBuilding = _c.selectedBuilding, selectedTower = _c.selectedTower, selectedApartment = _c.selectedApartment;
    var _d = useState({
        label: '',
        value: '',
    }), placeData = _d[0], setPlaceData = _d[1];
    var _e = useState(null), anchorRefAmenity = _e[0], setAnchorRefAmenity = _e[1];
    var _f = useState(null), anchorRefTime = _f[0], setAnchorRefTime = _f[1];
    var _g = useState(null), anchorRefApt = _g[0], setAnchorRefApt = _g[1];
    useClientCode({
        selectedApartment: selectedApartment,
        selectedBuilding: selectedBuilding,
        selectedTower: selectedTower,
        setClientCode: setClientCode,
        placeData: placeData,
    });
    var _h = useAmenitiesAndSchedules({
        selectedBuilding: selectedBuilding,
        amenity: amenity,
        date: date,
        setExtras: setExtras,
    }), amenities = _h.amenities, schedules = _h.schedules;
    var handleOutsideClick = function () {
        setAnchorRefAmenity(null);
        setAnchorRefTime(null);
        setAnchorRefApt(null);
    };
    var isAvailable = function (date) {
        var _a;
        return !!((_a = amenity === null || amenity === void 0 ? void 0 : amenity.availability) === null || _a === void 0 ? void 0 : _a.length)
            ? amenity === null || amenity === void 0 ? void 0 : amenity.availability[date.getDay() === 0 ? 6 : date.getDay() - 1] : true;
    };
    return (React.createElement(BoxContainer, null,
        React.createElement(InputComponent, { disabled: true, placeHolder: "A01L0001P", type: CLIENT_KEY, data: clientCode, error: {}, label: CLIENT_CODE, onChangeFunction: null }),
        React.createElement(InputComponent, { type: BUILDING_KEY, data: (_b = selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.label) === null || _b === void 0 ? void 0 : _b.toString(), error: {}, label: BUILDING, onChangeFunction: function () { return null; }, disabled: true }),
        React.createElement(SelectComponent, { label: APT, type: APT_KEY, data: placeData.label, errors: {}, img: openedArrow, anchorRef: anchorRefApt, setAnchorRef: setAnchorRefApt }),
        React.createElement(Popover, { data: API_DATA, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefApt, setSelected: setPlaceData, isFilter: false, className: "large-form" }),
        React.createElement(SelectComponent, { label: AMENITY, type: AMENITY_KEY, data: !amenities.length ? AMENITIES_NOT_AVAILABLE : amenity.label, errors: {}, img: openedArrow, anchorRef: anchorRefAmenity, setAnchorRef: setAnchorRefAmenity }),
        !!amenities.length && (React.createElement(Popover, { data: amenities, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefAmenity, setSelected: setAmenity, isFilter: false, className: "large-form" })),
        React.createElement(InputContainer, { className: "height" },
            React.createElement(Label, null, RESERVATION_DATE),
            React.createElement(CalendarComponent, { selected: date, onChange: function (e) { return setDate(e); }, customInput: React.createElement(CustomInput, null), minDate: moment().toDate(), filterDate: isAvailable })),
        React.createElement(SelectComponent, { label: SCHEDULE, type: TIME_KEY, data: amenity.label && !schedules.length
                ? SCHEDULES_NOT_AVAILABLE
                : time.label, errors: {}, img: openedArrow, anchorRef: anchorRefTime, setAnchorRef: setAnchorRefTime }),
        !!schedules.length && (React.createElement(Popover, { data: schedules, handleOutsideClick: handleOutsideClick, anchorRef: anchorRefTime, setSelected: setTime, isFilter: false, className: "large-form" }))));
};
