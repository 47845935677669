import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AdminLayout } from 'src/Layouts';
import { CREATE_RESERVATION_TITLE, RESERVATION_SUCCESS_MESSAGE, ROUTES, } from 'src/Utils';
import { ReservationForm } from './ReservationForm/ReservationForm';
import { ExtraForm } from './ExtraForm/ExtraForm';
import { createReservation } from './services';
import { Container, InnerContainer, SuccessSnackbar, Title } from './styles';
import { useAppDispatch } from '../../redux/hooks';
import { setFormSelected } from '../../redux/slices/tableFiltersSlice';
var CreateReservation = function () {
    var push = useHistory().push;
    var dispatch = useAppDispatch();
    var _a = useState(''), clientCode = _a[0], setClientCode = _a[1];
    var _b = useState(moment().toDate()), date = _b[0], setDate = _b[1];
    var _c = useState({
        label: '',
        value: 0,
        price: 0,
    }), time = _c[0], setTime = _c[1];
    var _d = useState({
        label: '',
        value: 0,
    }), amenity = _d[0], setAmenity = _d[1];
    var _e = useState([]), extras = _e[0], setExtras = _e[1];
    var _f = useState([]), selectedExtras = _f[0], setSelectedExtras = _f[1];
    var _g = useState(false), open = _g[0], setOpen = _g[1];
    var handleClick = function () { return setOpen(true); };
    var handleClose = function (event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
        push(ROUTES.reservations);
    };
    var mutateCreateReservation = useMutation(createReservation, {
        onSuccess: function () {
            handleClick();
            setTimeout(function () { return push(ROUTES.reservations); }, 2000);
        },
    })[0];
    var handleCreateReservation = function () {
        return mutateCreateReservation({
            amenityId: Number(amenity.value),
            clientCode: clientCode,
            date: date,
            scheduleId: time.value,
            extras: selectedExtras.reduce(function (acc, _a) {
                var name = _a.name, selected = _a.selected;
                if (selected)
                    acc.push(name);
                return acc;
            }, []),
        });
    };
    useEffect(function () {
        if (amenity.label) {
            var matchedAmenity = extras.find(function (item) { return item.name === amenity.label; });
            if (matchedAmenity)
                setSelectedExtras(matchedAmenity.extras);
        }
    }, [extras, amenity]);
    useEffect(function () {
        setTime({
            label: '',
            value: 0,
            price: 0,
        });
    }, [amenity, date]);
    useEffect(function () {
        dispatch(setFormSelected(true));
    }, []);
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, CREATE_RESERVATION_TITLE),
            React.createElement(InnerContainer, null,
                React.createElement(ReservationForm, { setExtras: setExtras, setAmenity: setAmenity, amenity: amenity, clientCode: clientCode, setClientCode: setClientCode, date: date, setDate: setDate, time: time, setTime: setTime }),
                React.createElement(ExtraForm, { extras: selectedExtras, amenity: amenity, time: time, setSelectedExtras: setSelectedExtras, handleCreateReservation: handleCreateReservation, isSubmitEnabled: !!amenity.value && !!time.value }))),
        React.createElement(SuccessSnackbar, { anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, open: open, autoHideDuration: 2000, onClose: handleClose, message: RESERVATION_SUCCESS_MESSAGE, action: React.createElement(IconButton, { size: "small", color: "inherit", onClick: handleClose },
                React.createElement(CloseIcon, { fontSize: "small" })) })));
};
export default CreateReservation;
