var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useState } from 'react';
import { useQuery } from 'react-query';
import { getData } from 'src/Utils';
export var useAmenitiesAndSchedules = function (_a) {
    var selectedBuilding = _a.selectedBuilding, amenity = _a.amenity, date = _a.date, setExtras = _a.setExtras;
    var _b = useState([]), amenities = _b[0], setAmenities = _b[1];
    var _c = useState([]), schedules = _c[0], setSchedules = _c[1];
    useQuery(['amenities', selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value], function () {
        return getData('/amenities', {
            building_id: selectedBuilding === null || selectedBuilding === void 0 ? void 0 : selectedBuilding.value,
        });
    }, {
        enabled: !!selectedBuilding,
        onSuccess: function (_a) {
            var amenities = _a.amenities;
            setAmenities(amenities.map(function (_a) {
                var name = _a.name, id = _a.id, currency = _a.currency, price = _a.price, availability = _a.availability;
                return ({
                    label: name,
                    value: id,
                    currency: currency,
                    price: price,
                    availability: availability,
                });
            }));
            setExtras(amenities.map(function (_a) {
                var name = _a.name, extras = _a.extras, currency = _a.currency;
                return ({
                    name: name,
                    extras: extras.map(function (extra) { return (__assign(__assign({}, extra), { selected: false, currency: currency })); }),
                });
            }));
        },
    });
    useQuery(['amenities', 'availability', amenity.value, date], function () {
        return getData("/amenities/" + amenity.value + "/availability", {
            date: date,
        });
    }, {
        enabled: !!amenity.value && !!date,
        onSuccess: function (_a) {
            var schedules = _a.schedules;
            return setSchedules(schedules.reduce(function (acc, _a) {
                var time = _a.time, available = _a.available, id = _a.id, price = _a.price;
                if (available)
                    acc.push({ label: time, value: id, price: price });
                return acc;
            }, []));
        },
    });
    return { amenities: amenities, schedules: schedules };
};
