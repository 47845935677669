var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ExtraCheckbox } from 'src/Components';
import { AdminButton } from 'src/Components/Common/AdminButton';
import { ADD_EXTRAS, CANCEL, SAVE, TOTAL_COST } from 'src/Utils';
import { handleAmenityCurrency } from 'src/Views/MaintenancePages/ReservationPage/utils';
import { BoxContainer, ButtonsContainer, ButtonsRow, CheckboxesContainer, Label, Link, Text, TextContainer, } from '../styles';
export var ExtraForm = function (_a) {
    var extras = _a.extras, amenity = _a.amenity, schedule = _a.schedule, setSelectedExtras = _a.setSelectedExtras, handleEditReservation = _a.handleEditReservation, isSubmitEnabled = _a.isSubmitEnabled;
    var goBack = useHistory().goBack;
    var currencyToShow = handleAmenityCurrency(amenity.currency || 'pesos');
    var handlePrice = function () { var _a; return extras === null || extras === void 0 ? void 0 : extras.reduce(function (acc, _a) {
        var selected = _a.selected, price = _a.price;
        var accFloat = parseFloat(acc.toString());
        var priceFloat = parseFloat(price.toString());
        return selected ? accFloat + priceFloat : accFloat;
    }, parseFloat((_a = schedule === null || schedule === void 0 ? void 0 : schedule.price) === null || _a === void 0 ? void 0 : _a.toString())); };
    var handleSelected = function (index) {
        var updatedExtras = extras.map(function (extra, i) {
            return i === index ? __assign(__assign({}, extra), { selected: !extra.selected }) : extra;
        });
        setSelectedExtras(updatedExtras);
    };
    return (React.createElement(BoxContainer, null,
        !!(extras === null || extras === void 0 ? void 0 : extras.length) && React.createElement(Label, { className: "extra" }, ADD_EXTRAS),
        React.createElement(CheckboxesContainer, null, extras === null || extras === void 0 ? void 0 : extras.map(function (_a, index) {
            var name = _a.name, price = _a.price, selected = _a.selected, currency = _a.currency;
            return (React.createElement(ExtraCheckbox, { key: name + "-" + index, name: name, price: price, selected: selected, currency: currency, setSelected: function () { return handleSelected(index); } }));
        })),
        React.createElement(TextContainer, null,
            React.createElement(Text, null,
                TOTAL_COST,
                ":"),
            React.createElement(Text, null, currencyToShow === 'UR'
                ? handlePrice() + " " + currencyToShow
                : currencyToShow + " " + handlePrice())),
        React.createElement(ButtonsContainer, null,
            React.createElement(ButtonsRow, null,
                React.createElement(Link, { onClick: goBack }, CANCEL),
                React.createElement(AdminButton, { type: "button", onClick: handleEditReservation, className: "height", disabled: !isSubmitEnabled }, SAVE)))));
};
