import api from 'src/Utils/api';
export var editReservation = function (_a) {
    var id = _a.id, amenityId = _a.amenityId, date = _a.date, scheduleId = _a.scheduleId, clientCode = _a.clientCode, extras = _a.extras;
    return api.put("/bookings/" + id, {
        booking: {
            amenity_id: amenityId,
            day: date,
            schedule_id: scheduleId,
            numeric_client_code: clientCode,
            extras: extras,
        },
    });
};
