var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import Cookies from 'js-cookie';
import { JANUARY, FEBRUARY, MARCH, APRIL, MAY, JUNE, JULY, AUGUST, SEPTEMBER, OCTOBER, NOVEMBER, DECEMBER, GOOGLE_MAPS_API, ALL, SELECT, } from './constants';
export var removeCookies = function () {
    Cookies.remove('Access-Token');
    Cookies.remove('Client');
    Cookies.remove('Uid');
    Cookies.remove('Id');
    Cookies.remove('User');
    Cookies.remove('SperAdmin');
};
export var setCookies = function (accessToken, client, uid, id, type, isSuperAdmin) {
    Cookies.set('Access-Token', accessToken);
    Cookies.set('Client', client);
    Cookies.set('Uid', uid);
    Cookies.set('Id', id);
    Cookies.set('User', type);
    Cookies.set('SuperAdmin', isSuperAdmin);
};
export var getMonth = function (month) {
    switch (month) {
        case '01':
            return JANUARY;
        case '02':
            return FEBRUARY;
        case '03':
            return MARCH;
        case '04':
            return APRIL;
        case '05':
            return MAY;
        case '06':
            return JUNE;
        case '07':
            return JULY;
        case '08':
            return AUGUST;
        case '09':
            return SEPTEMBER;
        case '10':
            return OCTOBER;
        case '11':
            return NOVEMBER;
        case '12':
            return DECEMBER;
        default:
            return JANUARY;
    }
};
export var formatDate = function (date) {
    var result = date;
    if (date != '' && date.length < 9 && date.length > 4) {
        var newDate = new Date(date);
        var year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(newDate);
        var month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(newDate);
        var day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDate);
        result = day + " " + getMonth(month) + ", " + year;
    }
    return result;
};
export var dateExpired = function (dateString) {
    var today = new Date().setUTCHours(0, 0, 0, 0);
    var date = new Date(dateString).setUTCHours(0, 0, 0, 0);
    return today > date;
};
export var createHeadCells = function (label1, label2, label3, label4, label5, label6, label7) { return [
    { id: 'first', numeric: true, disablePadding: false, label: label1 },
    { id: 'second', numeric: true, disablePadding: false, label: label2 },
    { id: 'third', numeric: true, disablePadding: false, label: label3 },
    {
        id: 'fourth',
        numeric: true,
        disablePadding: false,
        label: label4 ? label4 : '',
    },
    {
        id: 'fifth',
        numeric: true,
        disablePadding: false,
        label: label5 ? label5 : '',
    },
    {
        id: 'sixth',
        numeric: true,
        disablePadding: false,
        label: label6 ? label6 : '',
    },
    {
        id: 'seventh',
        numeric: true,
        disablePadding: false,
        label: label7 ? label7 : '',
    },
]; };
function descendingComparator(a, b, orderBy, compareDateWithoutHours) {
    var firstValue = a[orderBy];
    var secondValue = b[orderBy];
    var isNull = function (value) {
        return (value == null || value == undefined) && value != '0';
    };
    if (isNull(firstValue) && isNull(secondValue))
        return 0;
    else if (isNull(secondValue))
        return -1;
    else if (isNull(firstValue))
        return 1;
    var isNumeric = function (value) { return !isNaN(parseFloat(value)); };
    secondValue = secondValue.toString().toUpperCase();
    firstValue = firstValue.toString().toUpperCase();
    if (firstValue.includes('/') && secondValue.includes('/')) {
        var _a = firstValue.split(' '), aDay = _a[0], aHour = _a[1];
        var _b = secondValue.split(' '), bDay = _b[0], bHour = _b[1];
        if (!compareDateWithoutHours && (!aHour || !bHour))
            return 0;
        var aDayArr = aDay.split('/');
        var aHourArr = aHour === null || aHour === void 0 ? void 0 : aHour.split(':');
        var bDayArr = bDay.split('/');
        var bHourArr = bHour === null || bHour === void 0 ? void 0 : bHour.split(':');
        firstValue = new Date(aDayArr[2], aDayArr[1] - 1, aDayArr[0], (aHourArr === null || aHourArr === void 0 ? void 0 : aHourArr.length) ? aHourArr[0] : 0, (aHourArr === null || aHourArr === void 0 ? void 0 : aHourArr.length) ? aHourArr[1] : 0);
        secondValue = new Date(bDayArr[2], bDayArr[1] - 1, bDayArr[0], (bHourArr === null || bHourArr === void 0 ? void 0 : bHourArr.length) ? bHourArr[0] : 0, (bHourArr === null || bHourArr === void 0 ? void 0 : bHourArr.length) ? bHourArr[1] : 0);
    }
    else if (firstValue.includes('-') && secondValue.includes('-')) {
        var _c = firstValue.split('-'), aStartHour = _c[0], aEndHour = _c[1];
        var _d = secondValue.split('-'), bStartHour = _d[0], bEndHour = _d[1];
        if (isNumeric(aStartHour) &&
            isNumeric(bStartHour) &&
            aStartHour === bStartHour) {
            firstValue = aEndHour;
            secondValue = bEndHour;
        }
    }
    else if (firstValue.includes('$') ||
        firstValue.includes('U$S') ||
        firstValue.includes('UR') ||
        secondValue.includes('$') ||
        secondValue.includes('U$S') ||
        secondValue.includes('UR')) {
        var numberA = firstValue.split(' ');
        var numberB = secondValue.split(' ');
        firstValue = numberA[1];
        secondValue = numberB[1];
    }
    else if (isNumeric(secondValue) && isNumeric(firstValue)) {
        secondValue = parseInt(secondValue);
        firstValue = parseInt(firstValue);
    }
    if (secondValue < firstValue) {
        return -1;
    }
    if (secondValue > firstValue) {
        return 1;
    }
    return 0;
}
export function createData(first, second, third, fourth, fifth, sixth, seventh, ID) {
    return { first: first, second: second, third: third, fourth: fourth, fifth: fifth, sixth: sixth, seventh: seventh, ID: ID || 0 };
}
export function getComparator(order, orderBy, compareDateWithoutHours) {
    return order === 'desc'
        ? function (a, b) { return descendingComparator(a, b, orderBy, compareDateWithoutHours); }
        : function (a, b) { return -descendingComparator(a, b, orderBy, compareDateWithoutHours); };
}
export var orderByState = function (rows, stateOrder, index) {
    var states = {
        0: [],
        1: [],
        2: [],
        3: [],
        5: [],
    };
    rows.forEach(function (row) {
        switch (row[index]) {
            case 'Vencido':
                states[0].push(row);
                return;
            case 'Pendiente':
                states[1].push(row);
                return;
            case 'En progreso':
                states[2].push(row);
                return;
            case 'Informado':
                states[3].push(row);
            default:
                states[5].push(row);
        }
    });
    var getIndex = function (state) {
        return state % 4;
    };
    return __spreadArrays(states[stateOrder], states[getIndex(stateOrder + 1)], states[getIndex(stateOrder + 2)], states[getIndex(stateOrder + 3)], states[5]);
};
export function stableSort(rows, comparator) {
    var stabilizedThis = rows.map(function (row, index) { return [row, index]; });
    stabilizedThis.sort(function (a, b) {
        var order = comparator(a[0], b[0]);
        if (order !== 0)
            return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(function (row) { return row[0]; });
}
export var includesWord = function (word1, word2) {
    return word1.toUpperCase().includes(word2.toUpperCase());
};
export var increaseTime = function (amount, time) {
    return (parseInt(time.toString().substring(0, 2)) + amount).toString() + ":" + time.toString().substring(3, 5);
};
export var downloadCSV = function (downloadLink, csvName) {
    if (downloadLink) {
        fetch(downloadLink, {
            method: 'GET',
        }).then(function (response) {
            response.arrayBuffer().then(function (buffer) {
                var url = window.URL.createObjectURL(new Blob([buffer]));
                var link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', csvName + ".csv");
                document.body.appendChild(link);
                link.click();
            });
        });
    }
};
export var downloadPDF = function (downloadLink, pdfName) {
    if (downloadLink) {
        fetch(downloadLink, {
            method: 'GET',
        }).then(function (response) {
            response.arrayBuffer().then(function (buffer) {
                var url = window.URL.createObjectURL(new Blob([buffer]));
                var link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', pdfName + ".pdf");
                document.body.appendChild(link);
                link.click();
            });
        });
    }
};
export var getMonthNumber = function (key, currentMonth) {
    var monthNumber = currentMonth - parseInt(key);
    if (monthNumber < 1)
        monthNumber += 12;
    return monthNumber;
};
export var capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export function htmlEntities(str) {
    return String(str)
        .replaceAll('&ntilde;', 'ñ')
        .replaceAll('&Ntilde;', 'Ñ')
        .replaceAll('&amp;', '&')
        .replaceAll('&Ntilde;', 'Ñ')
        .replaceAll('&ntilde;', 'ñ')
        .replaceAll('&Ntilde;', 'Ñ')
        .replaceAll('&Agrave;', 'À')
        .replaceAll('&Aacute;', 'Á')
        .replaceAll('&Acirc;', 'Â')
        .replaceAll('&Atilde;', 'Ã')
        .replaceAll('&Auml;', 'Ä')
        .replaceAll('&Aring;', 'Å')
        .replaceAll('&AElig;', 'Æ')
        .replaceAll('&Ccedil;', 'Ç')
        .replaceAll('&Egrave;', 'È')
        .replaceAll('&Eacute;', 'É')
        .replaceAll('&Ecirc;', 'Ê')
        .replaceAll('&Euml;', 'Ë')
        .replaceAll('&Igrave;', 'Ì')
        .replaceAll('&Iacute;', 'Í')
        .replaceAll('&Icirc;', 'Î')
        .replaceAll('&Iuml;', 'Ï')
        .replaceAll('&ETH;', 'Ð')
        .replaceAll('&Ntilde;', 'Ñ')
        .replaceAll('&Ograve;', 'Ò')
        .replaceAll('&Oacute;', 'Ó')
        .replaceAll('&Ocirc;', 'Ô')
        .replaceAll('&Otilde;', 'Õ')
        .replaceAll('&Ouml;', 'Ö')
        .replaceAll('&Oslash;', 'Ø')
        .replaceAll('&Ugrave;', 'Ù')
        .replaceAll('&Uacute;', 'Ú')
        .replaceAll('&Ucirc;', 'Û')
        .replaceAll('&Uuml;', 'Ü')
        .replaceAll('&Yacute;', 'Ý')
        .replaceAll('&THORN;', 'Þ')
        .replaceAll('&szlig;', 'ß')
        .replaceAll('&agrave;', 'à')
        .replaceAll('&aacute;', 'á')
        .replaceAll('&acirc;', 'â')
        .replaceAll('&atilde;', 'ã')
        .replaceAll('&auml;', 'ä')
        .replaceAll('&aring;', 'å')
        .replaceAll('&aelig;', 'æ')
        .replaceAll('&ccedil;', 'ç')
        .replaceAll('&egrave;', 'è')
        .replaceAll('&eacute;', 'é')
        .replaceAll('&ecirc;', 'ê')
        .replaceAll('&euml;', 'ë')
        .replaceAll('&igrave;', 'ì')
        .replaceAll('&iacute;', 'í')
        .replaceAll('&icirc;', 'î')
        .replaceAll('&iuml;', 'ï')
        .replaceAll('&eth;', 'ð')
        .replaceAll('&ntilde;', 'ñ')
        .replaceAll('&ograve;', 'ò')
        .replaceAll('&oacute;', 'ó')
        .replaceAll('&ocirc;', 'ô')
        .replaceAll('&otilde;', 'õ')
        .replaceAll('&ouml;', 'ö')
        .replaceAll('&oslash;', 'ø')
        .replaceAll('&ugrave;', 'ù')
        .replaceAll('&uacute;', 'ú')
        .replaceAll('&ucirc;', 'û')
        .replaceAll('&uuml;', 'ü')
        .replaceAll('&yacute;', 'ý')
        .replaceAll('&thorn;', 'þ')
        .replaceAll('&yuml;', 'ÿ');
}
export function generateGoogleMapCircle(_a) {
    var color = _a.color, lat = _a.lat, lng = _a.lng, rad = _a.rad, zoom = _a.zoom, size = _a.size;
    var weight = parseInt("" + (rad * Math.pow(2, zoom)) / 72223);
    var _color = '0x' + color.replace('#', '');
    var _size = size.width + "x" + size.height;
    var queries = "&zoom=15&size=" + _size + "&scale=2&path=color:" + _color + "|weight:" + weight;
    var key = "&key=" + (process.env.REACT_APP_GOOGLE_API_KEY || '');
    var generateCoordinate = function (lat, lng) {
        return "|" + lat.toFixed(4) + "," + lng.toFixed(4);
    };
    var circle = "" + generateCoordinate(lat, lng) + generateCoordinate(lat, lng + 0.0001);
    var staticMapSrc = GOOGLE_MAPS_API + queries + circle + key;
    return staticMapSrc;
}
export function validateMail(mail) {
    var regexMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexMail.test(mail);
}
export var selectedAll = { label: ALL, value: '' };
export var noTower = { label: SELECT, value: '_' };
