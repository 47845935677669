import api from 'src/Utils/api';
export var createReservation = function (_a) {
    var amenityId = _a.amenityId, date = _a.date, scheduleId = _a.scheduleId, clientCode = _a.clientCode, extras = _a.extras;
    return api.post('/bookings', {
        booking: {
            amenity_id: amenityId,
            day: date,
            schedule_id: scheduleId,
            numeric_client_code: clientCode,
            extras: extras,
        },
    });
};
