import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AdminLayout } from '../../Layouts';
import { Container, InnerContainer, SuccessSnackbar, Title } from './styles';
import { ReservationForm } from './ReservationForm/ReservationForm';
import { EDIT_RESERVATION, RESERVATION_EDITED_SUCCESS_MESSAGE, ROUTES, } from '../../Utils';
import { ExtraForm } from './ExtraForm/ExtraForm';
import { editReservation } from './services';
import useBookingValues from './hooks/useBookingValues';
import { setFormSelected } from '../../redux/slices/tableFiltersSlice';
import { useAppDispatch } from '../../redux/hooks';
var EditReservation = function () {
    var dispatch = useAppDispatch();
    var id = useParams().id;
    var push = useHistory().push;
    var mutateEditReservation = useMutation(editReservation, {
        onSuccess: function () {
            handleClick();
            setTimeout(function () { return push(ROUTES.reservations); }, 2000);
        },
    })[0];
    var bookingValues = useBookingValues({ id: id });
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleClick = function () { return setOpen(true); };
    var handleClose = function (event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
        push(ROUTES.reservations);
    };
    var handleEditReservation = function () {
        var _a;
        return mutateEditReservation({
            id: id,
            amenityId: Number(bookingValues.amenity.value),
            clientCode: (_a = bookingValues.data) === null || _a === void 0 ? void 0 : _a.numeric_client_code,
            date: bookingValues.date,
            scheduleId: bookingValues.schedule.value,
            extras: bookingValues.selectedExtras.reduce(function (acc, _a) {
                var name = _a.name, selected = _a.selected;
                if (selected)
                    acc.push(name);
                return acc;
            }, []),
        });
    };
    useEffect(function () {
        dispatch(setFormSelected(true));
    }, []);
    return (React.createElement(AdminLayout, null,
        React.createElement(Container, null,
            React.createElement(Title, null, EDIT_RESERVATION),
            React.createElement(InnerContainer, null,
                React.createElement(ReservationForm, { data: bookingValues === null || bookingValues === void 0 ? void 0 : bookingValues.data, amenity: bookingValues.amenity, setAmenity: bookingValues.setAmenity, date: bookingValues.date, setDate: bookingValues.setDate, schedule: bookingValues.schedule, setSchedule: bookingValues.setSchedule, setExtras: bookingValues.setExtras }),
                React.createElement(ExtraForm, { extras: bookingValues.selectedExtras, amenity: bookingValues.amenity, schedule: bookingValues.schedule, setSelectedExtras: bookingValues.setSelectedExtras, handleEditReservation: handleEditReservation, isSubmitEnabled: !!bookingValues.amenity.value && !!bookingValues.schedule.value }))),
        React.createElement(SuccessSnackbar, { anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            }, open: open, autoHideDuration: 2000, onClose: handleClose, message: RESERVATION_EDITED_SUCCESS_MESSAGE, action: React.createElement(IconButton, { size: "small", color: "inherit", onClick: handleClose },
                React.createElement(CloseIcon, { fontSize: "small" })) })));
};
export default EditReservation;
