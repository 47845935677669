import { useEffect, useState } from 'react';
var useBookingInitialValues = function (_a) {
    var data = _a.data, amenities = _a.amenities, schedules = _a.schedules, setAmenity = _a.setAmenity, setSchedule = _a.setSchedule, setDate = _a.setDate;
    var _b = useState(), initialDate = _b[0], setInitialDate = _b[1];
    var _c = useState(), initialSchedule = _c[0], setInitialSchedule = _c[1];
    var _d = useState(), initialAmenity = _d[0], setInitialAmenity = _d[1];
    useEffect(function () {
        if (!initialAmenity && amenities.length && data) {
            var amenityToEdit = amenities.find(function (_a) {
                var label = _a.label;
                return label === (data === null || data === void 0 ? void 0 : data.amenity_name);
            });
            amenityToEdit && setAmenity(amenityToEdit);
            var dateArray = data === null || data === void 0 ? void 0 : data.day.split('-');
            dateArray &&
                setDate(new Date(parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2])));
            setInitialAmenity(data === null || data === void 0 ? void 0 : data.amenity_name);
            setInitialDate(data === null || data === void 0 ? void 0 : data.day);
        }
    }, [amenities]);
    useEffect(function () {
        if (!initialSchedule && schedules.length && data) {
            var scheduleToEdit = schedules.find(function (_a) {
                var label = _a.label;
                return label === data.schedule;
            });
            scheduleToEdit && setSchedule(scheduleToEdit);
            setInitialSchedule(data === null || data === void 0 ? void 0 : data.schedule);
        }
    }, [schedules]);
    return { initialAmenity: initialAmenity, initialDate: initialDate, initialSchedule: initialSchedule };
};
export default useBookingInitialValues;
