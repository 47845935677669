import React from 'react';
import { ReactQueryDevtools } from 'react-query-devtools';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Root from './Root';
import { AssemblyDocumentsPage, Login, ResetPassword, ForgotPassword, ContactPage, AboutUs, HomePage, BillsPage, SettlementPage, SettlementUploadPage, SummonsPage, NewSummon, PaymentHistory, CommonExpensesPage, NewReceipt, EditReceipt, PersonalInfo, EditPersonalInfo, ChangePassword, NewClientCode, OtherDocumentsPage, ApartmentsPage, NewAdminPage, ContactsPage, BuildingsPage, NewContact, MessagesPage, AdminsPage, CreateAccount, ViewContact, EditPassword, ProvidersPage, NewProvider, EditProvider, NewMessage, EditMessage, PaymentMethods, RealEstate, CollectionManagement, Sistarbanc, Banred, PDFGenerator, RealEstatePropertiesPage, RealEstateDetailPage, ReservationPage, CreateReservation, ReservationDetails, } from './Views';
import { ROUTES } from './Utils/constants';
import PrivateRoute from './Utils/privateRoutes';
import { ModifyContact } from './Views/MaintenancePages';
import PrivacyPolicy from './Views/PrivacyPolicy';
import ApartmentContactsPage from './Views/MaintenancePages/ApartmentContacts/ApartmentContactsPage';
import EditReservation from './Views/EditReservation';
import { Provider } from 'react-redux';
import store from './redux/store';
var App = function () {
    var home = ROUTES.home, landing = ROUTES.landing, contact = ROUTES.contact, about = ROUTES.about, login = ROUTES.login, reset_password = ROUTES.reset_password, new_password = ROUTES.new_password, dashboard = ROUTES.dashboard, minutes = ROUTES.minutes, settlement = ROUTES.settlement, settlement_upload = ROUTES.settlement_upload, bill = ROUTES.bill, summons = ROUTES.summons, new_summon = ROUTES.new_summon, payment = ROUTES.payment, receipt = ROUTES.receipt, new_receipt = ROUTES.new_receipt, edit_receipt = ROUTES.edit_receipt, account = ROUTES.account, editAccount = ROUTES.editAccount, changePassword = ROUTES.changePassword, newClientCode = ROUTES.newClientCode, otherDocuments = ROUTES.otherDocuments, apartments = ROUTES.apartments, contacts = ROUTES.contacts, apartments_contacts = ROUTES.apartments_contacts, buildings = ROUTES.buildings, new_contact = ROUTES.new_contact, messages = ROUTES.messages, new_message = ROUTES.new_message, edit_message = ROUTES.edit_message, view_message = ROUTES.view_message, admins = ROUTES.admins, providers = ROUTES.providers, new_provider = ROUTES.new_provider, modify_provider = ROUTES.modify_provider, create_account = ROUTES.create_account, create_admin = ROUTES.create_admin, contact_detail = ROUTES.contact_detail, modify_contact = ROUTES.modify_contact, online_payment = ROUTES.online_payment, in_person_payment = ROUTES.in_person_payment, modify_password = ROUTES.modify_password, modify_email = ROUTES.modify_email, real_estate = ROUTES.real_estate, real_estate_properties = ROUTES.real_estate_properties, real_estate_detail = ROUTES.real_estate_detail, collection_manager = ROUTES.collection_manager, sistarbanc = ROUTES.sistarbanc, banred = ROUTES.banred, pdf_generator = ROUTES.pdf_generator, privacy = ROUTES.privacy, reservations = ROUTES.reservations, create_reservation = ROUTES.create_reservation, reservation_details = ROUTES.reservation_details, edit_reservation = ROUTES.edit_reservation;
    return (React.createElement(Provider, { store: store },
        React.createElement(BrowserRouter, null,
            React.createElement(Switch, null,
                React.createElement(Route, { path: [home, landing], exact: true, component: Root }),
                React.createElement(Route, { path: contact, exact: true, component: ContactPage }),
                React.createElement(Route, { path: about, exact: true, component: AboutUs }),
                React.createElement(Route, { path: privacy, exact: true, component: PrivacyPolicy }),
                React.createElement(Route, { path: login, exact: true, component: Login }),
                React.createElement(Route, { path: banred, exact: true, component: Banred }),
                React.createElement(Route, { path: pdf_generator, exact: true, component: PDFGenerator }),
                React.createElement(Route, { path: new_password + "/:token", exact: true, component: ResetPassword }),
                React.createElement(Route, { path: reset_password, exact: true, component: ForgotPassword }),
                React.createElement(Route, { path: create_account, exact: true, component: CreateAccount }),
                React.createElement(Route, { path: real_estate, exact: true, component: RealEstate }),
                React.createElement(Route, { path: real_estate_properties, exact: true, component: RealEstatePropertiesPage }),
                React.createElement(Route, { path: real_estate_detail, exact: true, component: RealEstateDetailPage }),
                React.createElement(PrivateRoute, { path: dashboard, exact: true, component: HomePage, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: reservations, exact: true, component: ReservationPage }),
                React.createElement(PrivateRoute, { path: create_reservation, exact: true, component: CreateReservation }),
                React.createElement(PrivateRoute, { path: reservation_details, exact: true, component: ReservationDetails }),
                React.createElement(PrivateRoute, { path: edit_reservation, exact: true, component: EditReservation }),
                React.createElement(PrivateRoute, { path: minutes, exact: true, component: AssemblyDocumentsPage, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: admins, exact: true, component: AdminsPage, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: bill, exact: true, component: BillsPage, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: settlement, exact: true, component: SettlementPage, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: settlement_upload, exact: true, component: SettlementUploadPage }),
                React.createElement(PrivateRoute, { path: summons, exact: true, component: SummonsPage, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: new_summon + "/:label/:id", exact: true, component: NewSummon }),
                React.createElement(PrivateRoute, { path: payment, exact: true, component: PaymentHistory, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: receipt, exact: true, component: CommonExpensesPage, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: new_receipt, exact: true, component: NewReceipt }),
                React.createElement(PrivateRoute, { path: edit_receipt + "/:id", exact: true, component: EditReceipt }),
                React.createElement(PrivateRoute, { path: account, exact: true, component: PersonalInfo, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: editAccount, exact: true, component: EditPersonalInfo, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: changePassword, exact: true, component: ChangePassword, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: newClientCode, exact: true, component: NewClientCode, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: otherDocuments, exact: true, component: OtherDocumentsPage, isContactAllowed: true }),
                React.createElement(PrivateRoute, { path: apartments, exact: true, component: ApartmentsPage }),
                React.createElement(PrivateRoute, { path: contacts, exact: true, component: ContactsPage }),
                React.createElement(PrivateRoute, { path: apartments_contacts, exact: true, component: ApartmentContactsPage }),
                React.createElement(PrivateRoute, { path: buildings, exact: true, component: BuildingsPage }),
                React.createElement(PrivateRoute, { path: new_contact, exact: true, component: NewContact }),
                React.createElement(PrivateRoute, { path: messages, exact: true, component: MessagesPage }),
                React.createElement(PrivateRoute, { path: new_message, exact: true, component: NewMessage }),
                React.createElement(PrivateRoute, { path: edit_message + "/:id", exact: true, component: function () { return React.createElement(EditMessage, null); } }),
                React.createElement(PrivateRoute, { path: view_message + "/:id", exact: true, component: function () { return React.createElement(EditMessage, { isView: true }); } }),
                React.createElement(PrivateRoute, { path: providers, exact: true, component: ProvidersPage }),
                React.createElement(PrivateRoute, { path: providers + "/:id", exact: true, component: ProvidersPage }),
                React.createElement(PrivateRoute, { path: modify_provider + "/:id", exact: true, component: EditProvider }),
                React.createElement(PrivateRoute, { path: new_provider, exact: true, component: NewProvider }),
                React.createElement(PrivateRoute, { path: contact_detail + "/:id", exact: true, component: ViewContact }),
                React.createElement(PrivateRoute, { path: modify_contact + "/:id", exact: true, component: ModifyContact }),
                React.createElement(PrivateRoute, { path: create_admin, exact: true, component: NewAdminPage }),
                React.createElement(PrivateRoute, { path: modify_password + "/:id", exact: true, component: function () { return React.createElement(EditPassword, null); } }),
                React.createElement(PrivateRoute, { path: modify_email + "/:id", exact: true, component: function () { return React.createElement(EditPassword, { isEmail: true }); } }),
                React.createElement(PrivateRoute, { path: online_payment + "/:id", exact: true, isContactAllowed: true, component: function () { return React.createElement(PaymentMethods, { online: true }); } }),
                React.createElement(PrivateRoute, { path: in_person_payment + "/:id", exact: true, isContactAllowed: true, component: function () { return React.createElement(PaymentMethods, null); } }),
                React.createElement(PrivateRoute, { path: collection_manager, exact: true, isContactAllowed: true, component: function () { return React.createElement(CollectionManagement, null); } }),
                React.createElement(PrivateRoute, { path: sistarbanc, exact: true, component: function () { return React.createElement(Sistarbanc, null); } }))),
        React.createElement(ReactQueryDevtools, { initialIsOpen: false })));
};
export default App;
