var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RESERVATION_API_EDIT, getData } from '../../../Utils';
var useBookingValues = function (_a) {
    var id = _a.id;
    var _b = useState(new Date()), date = _b[0], setDate = _b[1];
    var _c = useState({
        label: '',
        value: 0,
        price: 0,
    }), schedule = _c[0], setSchedule = _c[1];
    var _d = useState({
        label: '',
        value: 0,
    }), amenity = _d[0], setAmenity = _d[1];
    var _e = useState([]), extras = _e[0], setExtras = _e[1];
    var _f = useState([]), selectedExtras = _f[0], setSelectedExtras = _f[1];
    var data = useQuery([RESERVATION_API_EDIT, id], function () { return getData("/bookings/" + id); }, {
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    }).data;
    useEffect(function () {
        if (amenity.label) {
            var matchedAmenity = extras.find(function (item) { return item.name === amenity.label; });
            if (matchedAmenity)
                setSelectedExtras(matchedAmenity.extras.map(function (extra) { return (__assign(__assign({}, extra), { selected: !!(data === null || data === void 0 ? void 0 : data.extras.includes(extra.name)) })); }));
        }
    }, [extras, amenity]);
    useEffect(function () {
        setSchedule({
            label: '',
            value: 0,
            price: 0,
        });
    }, [amenity, date]);
    return {
        date: date,
        schedule: schedule,
        amenity: amenity,
        extras: extras,
        selectedExtras: selectedExtras,
        data: data,
        setDate: setDate,
        setSchedule: setSchedule,
        setAmenity: setAmenity,
        setExtras: setExtras,
        setSelectedExtras: setSelectedExtras,
    };
};
export default useBookingValues;
