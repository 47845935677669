import { useState } from 'react';
import { useQuery } from 'react-query';
import { BASE_PRICE, getData } from 'src/Utils';
import { handleAmenityCurrency } from '../../MaintenancePages/ReservationPage/utils';
export var useAmenity = function (_a) {
    var amenityId = _a.amenityId, selectedExtras = _a.selectedExtras, _b = _a.currency, currency = _b === void 0 ? 'pesos' : _b, day = _a.day, time = _a.time;
    var _c = useState([]), extras = _c[0], setExtras = _c[1];
    var _d = useState([]), basePrice = _d[0], setBasePrice = _d[1];
    useQuery(['amenity', amenityId], function () { return getData("/amenities/" + amenityId); }, {
        enabled: !!amenityId,
        onSuccess: function (_a) {
            var _b;
            var extras = _a.extras;
            setExtras([
                {
                    title: (selectedExtras === null || selectedExtras === void 0 ? void 0 : selectedExtras.title) || '',
                    values: (selectedExtras === null || selectedExtras === void 0 ? void 0 : selectedExtras.values) && ((_b = selectedExtras === null || selectedExtras === void 0 ? void 0 : selectedExtras.values) === null || _b === void 0 ? void 0 : _b[0]) !== '-'
                        ? selectedExtras === null || selectedExtras === void 0 ? void 0 : selectedExtras.values.map(function (extra) {
                            var currencyToShow = handleAmenityCurrency(currency);
                            var findExtra = extras.find(function (elem) { return (elem === null || elem === void 0 ? void 0 : elem.name) === extra; });
                            return currencyToShow === 'UR'
                                ? (findExtra === null || findExtra === void 0 ? void 0 : findExtra.price) + " " + currencyToShow + " " + extra.toLocaleLowerCase()
                                : currencyToShow + " " + (findExtra === null || findExtra === void 0 ? void 0 : findExtra.price) + " " + extra.toLocaleLowerCase();
                        }) : ['-'],
                },
            ]);
        },
    });
    useQuery(['amenities', 'availability', amenityId, day], function () {
        return getData("/amenities/" + amenityId + "/availability", {
            date: day,
        });
    }, {
        enabled: !!amenityId && !!day,
        onSuccess: function (_a) {
            var _b;
            var schedules = _a.schedules;
            var findSchedule = (_b = schedules === null || schedules === void 0 ? void 0 : schedules.find(function (elem) { return (elem === null || elem === void 0 ? void 0 : elem.time) === time; })) === null || _b === void 0 ? void 0 : _b.price;
            var currencyToShow = handleAmenityCurrency(currency);
            setBasePrice([
                {
                    title: BASE_PRICE,
                    values: [
                        currencyToShow === 'UR'
                            ? findSchedule + " " + currencyToShow
                            : currencyToShow + " " + findSchedule,
                    ],
                },
            ]);
        },
    });
    return { extras: extras, basePrice: basePrice };
};
